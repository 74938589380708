import * as Yup from 'yup';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import add from 'date-fns/add';
import Consts from '../../../app/Consts';
import {combineUtcDateTime, isValidDate, formatDate} from '../../../utils/DateUtils';

export const stepMixAndMatchDetailsValidationSchema = Yup.object({
  description: Yup.string()
    .required(Consts.ValidationMessage.Required)
    .test(
      'non-printable-chars',
      Consts.ValidationMessage.DealNonPrintableCharsError,
      function (value) {
        // eslint-disable-next-line no-control-regex
        return !/[\u0000-\u001F\u007F]/g.test(value ?? '');
      }
    ),
  departments: Yup.array()
    .min(1, 'Select at least one department')
    .required(Consts.ValidationMessage.Required),
  startAt: Yup.date()
    .required(Consts.ValidationMessage.Required)
    .nullable()
    .typeError(Consts.ValidationMessage.Date.FormatError)
    .test(
      'cut-off date check',
      'The start date cannot be set before the cut-off date',
      function (value) {
        if (!value) {
          return true;
        }
        const lockDate = this.parent[Consts.ConfigNameEnum.DealLockDate];
        const cutoffDate = this.parent[Consts.ConfigNameEnum.DealCutoffDate];
        if (
          lockDate &&
          isAfter(new Date(), new Date(lockDate)) &&
          cutoffDate &&
          isBefore(new Date(value), new Date(cutoffDate))
        ) {
          return this.createError({
            message: `The start date cannot be set before the cut-off date (${formatDate(
              cutoffDate
            )})`,
          });
        }
        return true;
      }
    )
    .test(
      'draft and ready to start, start date check',
      Consts.ValidationMessage.Date.MinimumTenMinutesFromNow,
      function (value) {
        if (!value) {
          return true;
        }
        const claimingStatus = this.resolve(Yup.ref('claimingStatus'));
        if (
          claimingStatus === Consts.AgreementStatusEnum.ReadyToStart ||
          claimingStatus === Consts.AgreementStatusEnum.Draft
        ) {
          const isStartTimeSpecified = !!this.resolve(Yup.ref('isStartTimeSpecified'));
          const startTime = this.resolve(Yup.ref('startTime')) as Date | null;
          const startDateTime = isStartTimeSpecified ? combineUtcDateTime(value, startTime) : value;
          return isAfter(new Date(startDateTime), add(new Date(), {minutes: 10}));
        }
        return true;
      }
    ),
  endAt: Yup.date()
    .max(Consts.Date.MaxEndDateMonthsFromNow, Consts.ValidationMessage.Date.MaxEndDateRangeError)
    .required(Consts.ValidationMessage.Required)
    .nullable()
    .typeError(Consts.ValidationMessage.Date.FormatError)
    .test('end date validation', '', function (value) {
      const claimingStatus = this.resolve(Yup.ref('claimingStatus'));
      if (!value) {
        return true;
      }
      const isStartTimeSpecified = !!this.resolve(Yup.ref('isStartTimeSpecified'));
      const startTime = this.resolve(Yup.ref('startTime')) as Date | null;
      const startAt = this.resolve(Yup.ref('startAt')) as Date | null;
      const startDateTime = isValidDate(startAt)
        ? combineUtcDateTime(startAt as Date, isStartTimeSpecified ? startTime : null)
        : null;

      const isEndTimeSpecified = !!this.resolve(Yup.ref('isEndTimeSpecified'));
      const endTime = this.resolve(Yup.ref('endTime')) as Date | null;
      const endDateTime = isEndTimeSpecified ? combineUtcDateTime(value, endTime) : value;

      if (
        claimingStatus === Consts.AgreementStatusEnum.ReadyToStart ||
        claimingStatus === Consts.AgreementStatusEnum.Draft ||
        claimingStatus === Consts.AgreementStatusEnum.InProgress
      ) {
        if (
          isValidDate(startDateTime) &&
          isBefore(new Date(endDateTime), new Date(startDateTime as Date))
        ) {
          return this.createError({
            message: Consts.ValidationMessage.Date.EndDateRangeError,
          });
        }
      }

      if (claimingStatus === Consts.AgreementStatusEnum.InProgress) {
        if (isBefore(new Date(endDateTime), add(new Date(), {minutes: 10}))) {
          return this.createError({
            message: Consts.ValidationMessage.Date.MinimumTenMinutesFromNow,
          });
        }
      }

      if (
        isValidDate(endDateTime) &&
        isBefore(new Date(endDateTime), add(new Date(), {minutes: 10}))
      ) {
        return this.createError({
          message: Consts.ValidationMessage.Date.MinimumTenMinutesFromNow,
        });
      }

      return true;
    }),
  receiptDescription: Yup.string()
    .max(40, 'Receipt description cannot exceed 40 characters')
    .test('required', Consts.ValidationMessage.Required, function (value) {
      return (value ?? '').trim().length > 0;
    })
    .test(
      'non-printable-chars',
      Consts.ValidationMessage.DealNonPrintableCharsError,
      function (value) {
        // eslint-disable-next-line no-control-regex
        return !/[\u0000-\u001F\u007F]/g.test(value ?? '');
      }
    ),
  startTime: Yup.date().nullable().typeError(Consts.ValidationMessage.Time.FormatError),
  endTime: Yup.date()
    .nullable()
    .typeError(Consts.ValidationMessage.Time.FormatError)
    .test(
      'start-end-time-validation',
      Consts.ValidationMessage.Time.EndTimeRangeError,
      function (value) {
        if (isValidDate(this.parent.startAt) && isValidDate(this.parent.endAt)) {
          return (
            !isValidDate(this.parent.startTime) ||
            combineUtcDateTime(this.parent.startAt, this.parent.startTime) <=
              combineUtcDateTime(this.parent.endAt, value, true)
          );
        }
        return true;
      }
    )
    .test(
      'end-time-validation',
      Consts.ValidationMessage.Date.MinimumTenMinutesFromNow,
      function (value) {
        if (!value) {
          return true;
        }
        const claimingStatus = this.resolve(Yup.ref('claimingStatus'));
        const endAt = this.resolve(Yup.ref('endAt')) as Date | null;
        if (
          claimingStatus === Consts.AgreementStatusEnum.InProgress &&
          isValidDate(endAt) &&
          !!value
        ) {
          const combinedEndDateTime = combineUtcDateTime(endAt as Date, value);
          if (isValidDate(combinedEndDateTime)) {
            return isAfter(combinedEndDateTime, add(new Date(), {minutes: 10}));
          }
        }
        return true;
      }
    ),
});
